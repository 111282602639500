import { ContactAddRequest, ContactState } from "./types";

// Action Types
export const SET_STORE_CONTACTS_LIST = "SET_STORE_CONTACTS_LIST";
export const SET_STORE_CONTACT_DETAILS = "SET_STORE_CONTACT_DETAILS";



export const setStoreContactList = (contactList: ContactState) => ({
  type: SET_STORE_CONTACTS_LIST,
  payload: contactList,
});

export const setStoreContactDetails = (contactList: ContactAddRequest) => ({
  type: SET_STORE_CONTACT_DETAILS,
  payload: contactList,
});



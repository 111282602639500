/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable-next-line */

import { User ,ConfirmationPopup } from './types';



export const ActionTypes = {
    SEND_USER_DATA :'SEND_USER_DATA',
     GET_ADMIN_LIST :'GET_ADMIN_LIST',
     GET_EMPLOYEE_LIST :'GET_EMPLOYEE_LIST',
     GET_TEACHINGSTAFF_LIST :'GET_TEACHINGSTAFF_LIST',
     GET_IQA_LIST :'GET_IQA_LIST',
     GET_EPA_LIST :'GET_EPA_LIST',
     GET_REFERER_LIST :'GET_REFERER_LIST',
     GET_LEARNER_LIST :'GET_LEARNER_LIST',
     SHOW_CONFORMTION_POP: 'SHOW_CONFORMTION_POP',
     GET_MANAGER_LIST:'GET_MANAGER_LIST',
     GET_ADDRESS_LIST:'GET_ADDRESS_LIST',
     Search:'Search',
     GET_GROUP_LIST:'GET_GROUP_LIST',
     GROUP_CREATED :'GROUP_CREATED',
     POST_CODE:'POST_CODE',
     GET_COMPANY:'GET_COMPANY',

    
  
  };

export const setCreateAdminData = (payload: User) => ({
    type: ActionTypes.SEND_USER_DATA,
    payload,
  });
  export const setCompanyData = (payload: User) => ({
    type: ActionTypes.GET_COMPANY,
    payload,
  });
  export const setUpdateAdminData = (payload: User) => ({
    type: ActionTypes.SEND_USER_DATA,
    payload,
  });
  export const setConformationPop = (payload: any) => ({
    type: ActionTypes.SHOW_CONFORMTION_POP,
    payload,
  });
  export const getCreateAdminData = (payload: User) => ({
    type: ActionTypes.SEND_USER_DATA,
    payload,
  });

  export const setAdminList = (payload: any) => ({
    type: ActionTypes.GET_ADMIN_LIST,
    payload,
  });
  export const setEmployeeList = (payload: any) => ({
    type: ActionTypes.GET_EMPLOYEE_LIST,
    payload,
  });
  export const setTeachingStaffList = (payload: any) => ({
    type: ActionTypes.GET_TEACHINGSTAFF_LIST,
    payload,
  });
  export const setIqaList = (payload: any) => ({
    type: ActionTypes.GET_IQA_LIST,
    payload,
  });
  export const setEpaList = (payload: any) => ({
    type: ActionTypes.GET_EPA_LIST,
    payload,
  });
  export const setRefererList = (payload: any) => ({
    type: ActionTypes.GET_REFERER_LIST,
    payload,
  });
  export const setLearnerList =(payload:any)=>({
    type:ActionTypes.GET_LEARNER_LIST,
    payload,
  })
  export const setGroupList =(payload:any)=>({
    type:ActionTypes.GET_GROUP_LIST,
    payload,
  })
  export const setGroupCreated =(payload:any)=>({
    type:ActionTypes.GROUP_CREATED,
    payload,
  })
  export const setManagerList = (payload: User) => ({
    type: ActionTypes.GET_MANAGER_LIST,
    payload,
  });
  export const setAdddressList = (payload: User) => ({
    type: ActionTypes.GET_ADDRESS_LIST,
    payload,
  });
  export const setPostCodeList = (payload: User) => ({
    type: ActionTypes.POST_CODE,
    payload,
  });
  // export const setTeachingStaffList = (payload: User) => ({
  //   type: ActionTypes.GET_ADDRESS_LIST,
  //   payload,
  // });
  export const setSearch = (payload: string) => ({
    type: ActionTypes.Search,
    payload,
  });







/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { usePosterReducers } from "../redux/usePoster";
import { handleSocketResponse } from "./WebSocketResponse";
import { toast } from "react-toastify";
import App_url from "../Components/constants/static";
import { setAdminList } from "../redux/modules/admin/users/action";

interface WebSocketContextType {
  websocket: WebSocket | null;
  send: (data: any) => void;
  receivedMessage: any | null;
  isConnect: boolean;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const useModalData = () => {
  const context = useContext(WebSocketContext);
  const posterData = usePosterReducers();
  const [PreviewPopup, setPreviewPopupState] = useState<{ show?: string; data?: any }>({});
  
  const setPreviewPopup = (popup?: { show?: string; data?: any }) => {
    setPreviewPopupState(popup || {});
  };
  
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  
  return {
    ...context,
    ...posterData,
    PreviewPopup,
    setPreviewPopup,
  };
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [websocket, setWebSocket] = useState<WebSocket | null>(null);
  const [receivedMessage, setReceivedMessage] = useState<any | null>(null);
  const [isConnect, setIsConnect] = useState<boolean>(false);
  const { user_data } = usePosterReducers();
  const dispatch: any = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  function isAuthPathName(): boolean {
    if (
      location.pathname === App_url.link.SignIn ||
      location.pathname === App_url.link.ForgetPassword ||
      location.pathname === App_url.link.ResetPassword ||
      location.pathname === App_url.link.EnterOTP
    )
      return false;
    return true;
  }

  const connectWebSocket = () => {
    const socket = new WebSocket(
      `${process.env.REACT_APP_ENDPOINT_URL}/ws/${user_data?.access_token}/` || ""
    );
    
    socket.onopen = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsConnect(true);
      setWebSocket(socket);
    };

    socket.onclose = () => {
      setIsConnect(false);
      setWebSocket(null);
    };

    socket.onerror = (error) => {
      setIsConnect(false);
      setWebSocket(null);
    };

    socket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      
      if (data?.url === 'unauthorized' && isAuthPathName()) {
        navigate('/login');
      }

      console.log("socket.onmessage ::", data);

      if (data?.response?.status === 200) {
        setReceivedMessage(data);
        // Dispatch the action and pass `send` function as an argument
        await dispatch(await handleSocketResponse(data, dispatch, user_data, send));
        console.log("200 message ", data?.response?.msg);
      }

      if (data?.response?.status === 400) {
        setReceivedMessage(data);
        console.log("error message ", data?.response?.msg);
        if (data?.response?.msg === "User details not found.") {
          dispatch(setAdminList([]));
        } else {
          toast.warning(data?.response?.msg);
        }
      }
    };

    return socket;
  };

  useEffect(() => {
    let socket: WebSocket | null = null;
    if (user_data?.user?.is_active && isAuthPathName()) {
      socket = connectWebSocket();
      const reconnectInterval = setInterval(() => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
          socket = connectWebSocket();
        }
      }, 3000);
      return () => {
        clearInterval(reconnectInterval);
        if (socket) {
          socket.close();
        }
      };
    }
  }, [user_data?.access_token, dispatch]);

  const send = (data: any) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      if (isConnect) {
        // Send data through the WebSocket
        websocket.send(JSON.stringify(data));
        console.log("Send ::", data);
      } else {
        console.log("not connected ::");
      }
    } else {
      console.log("WebSocket is not ready.");
    }
  };

  const contextValue: WebSocketContextType = {
    websocket: websocket,
    send: send,
    receivedMessage: receivedMessage,
    isConnect: isConnect,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from "./action";
import { IRequestRes } from "./types";

const initialState: IRequestRes = {
  socketResponse:{
    data: null,
    errors: null,
    request: null,
    action: null,
    status: null,
    type: null,
    payload: null,
  },
  ModalPopup:{
    show:"",
  }
};

const requestReducers = ( state: IRequestRes = initialState, action: any ) => {
  switch (action.type) {
    case ActionTypes.SET_STORE_ACTION_TYPE:{
      return {
        ...state,
        socketResponse: action?.payload
      }
    }
    case ActionTypes.SET_SHOW_MODAL_POPUP:{
      return {
        ...state,
        ModalPopup: action?.payload?action?.payload:null
      }
    }
    default:
      return state;
  }
};

export default requestReducers;

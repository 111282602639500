/* eslint-disable @typescript-eslint/no-unused-vars */

import { IShowModalPopup, ISocketResponseTypes } from "./types";

export const ActionTypes = {
  SET_STORE_ACTION_TYPE: "SET_STORE_ACTION_TYPE",
  SET_SHOW_MODAL_POPUP:'SET_SHOW_MODAL_POPUP',
};
export const setStoreSocketResponse = (payload?: ISocketResponseTypes) =>({
  type:ActionTypes?.SET_STORE_ACTION_TYPE,
  payload: payload
})
export const setShowModalPopup = (payload?: IShowModalPopup) =>({
  type:ActionTypes?.SET_SHOW_MODAL_POPUP,
  payload: payload
})
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable-next-line */

import { combineReducers, configureStore,  } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userDataReducers } from "./modules/user_data";
import { IApiResponse } from "./modules/user_data/types";
import requestReducers from "./modules/request/reducer";

import { userReducer } from "./modules/admin/users/reducer";
import programReducer from "./modules/admin/program/reducer";
import { ProgramData, ProgramResponse } from "./modules/admin/program/types";
import {  LearnerState } from "./modules/admin/learner/types";
import learnerReducer from "./modules/admin/learner/reducer";
import ContactReducer from "./modules/admin/contacts/reducer";
import { ContactState } from "./modules/admin/contacts/types";
import { INotificationResponse, IRequestInitialNotification } from "./modules/admin/notification/types";
import notificationReducer from "./modules/admin/notification/reducer";
import reviewReducer from "./modules/admin/review/reducer";
import attendanceReducer from "./modules/admin/attendance/reducer";
import OTJHReducer from "./modules/admin/otjh/reducer";
import progressReducer from "./modules/admin/progress/reducer";
import { IRequestInitialReview } from "./modules/admin/review/types";
import { IRequestInitialAttendance } from "./modules/admin/attendance/types";
import { IRequestInitialProgress } from "./modules/admin/progress/types";
import { IRequestInitialOTJH } from "./modules/admin/otjh/types";
export interface RootState {
  combinedReducer: {
    user_data: IApiResponse;
    request: ReturnType<typeof requestReducers>;
    users:any;
    user:any;
    contact: ContactState,
    notification?: IRequestInitialNotification,
    review?: IRequestInitialReview,
    attendance?: IRequestInitialAttendance,
    progress?: IRequestInitialProgress,
    OTJH?: IRequestInitialOTJH,
    program : ProgramResponse
    learner : LearnerState
  }
}


const combinedReducer:any = combineReducers({
  user_data: userDataReducers,
  users:userReducer,
  request: requestReducers,
  program : programReducer,
  learner : learnerReducer,
  contact: ContactReducer,
  notification:notificationReducer,
  review: reviewReducer,
  attendance: attendanceReducer,
  OTJH: OTJHReducer,
  progress: progressReducer
});

// const rootReducer = combineReducers({
//   combinedReducer: combinedReducer
// });



const rootReducer = combineReducers({
  combinedReducer: persistReducer(
    { key: "chathub-store", storage, blacklist: ["uiReducer"] },
    combinedReducer,
  ),
});



export function makeStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"], // Ignore serializability check for specific actions
        },
      }),
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = makeStore();
// export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };

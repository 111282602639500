import { SET_STORE_OTJH_LIST } from "./action";
import { IRequestInitialOTJH } from "./types";


const initialState: IRequestInitialOTJH = {
  OTJHList: {
    data: [],
    pagination: {
      current_page: 0,
      record_limit: 0,
      total_records: 0,
    },
  },
};

const OTJHReducer = (state = initialState, action: any): IRequestInitialOTJH => {
  switch (action.type) {
    case SET_STORE_OTJH_LIST:
      return {
        ...state,
        OTJHList: {
          ...state.OTJHList,
          data: action.payload.data ? [...action.payload.data] : [],
          pagination: {
            current_page: action.payload.pagination?.current_page || 0,
            record_limit: action.payload.pagination?.record_limit || 0,
            total_records: action.payload.pagination?.total_records || 0,
          },
        },
      };
    default:
      return state;
  }
};

export default OTJHReducer;

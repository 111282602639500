/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable-next-line */

import { toast } from "react-toastify";
import { Dispatch, } from "redux";
import { setStoreSocketResponse } from "../redux/modules/request/action";
import { setProgramData, setProgramList } from "../redux/modules/admin/program/action";
import { setForm, setLearner, setLearnerDetails, setUpdateLearner } from "../redux/modules/admin/learner/action";
import { setStoreContactDetails, setStoreContactList } from "../redux/modules/admin/contacts/action";
import { setNotificationList } from "../redux/modules/admin/notification/action";
import { setAdminList, setAdddressList, setConformationPop, setCreateAdminData, setGroupCreated, setGroupList, setManagerList, setTeachingStaffList, setIqaList, setEmployeeList, setEpaList, setRefererList, setLearnerList, setPostCodeList } from "../redux/modules/admin/users/action";
import App_url from "../Components/constants/static";
import { setProgressList } from "../redux/modules/admin/progress/action";
import { setAttendanceList } from "../redux/modules/admin/attendance/action";
import { setReviewList } from "../redux/modules/admin/review/action";
import { setOTJHList } from "../redux/modules/admin/otjh/action";

export const handleSocketResponse = async (
  data: any,
  dispatch: any,
  send: any,
  navigate?: any,
  type?: string,
  user_data?: any,
  access_token?: any,
) => (async (dispatch: Dispatch, getState: any) => {
  // const { requestLoader, modalLoader, PreviewPopup } = getState()?.combinedReducer?.uiReducer;
  const payloadRequest = {
    ...data,
    action: data?.request?.action,
    type: data?.request?.type,
    payload: data?.request?.payload,
  };
  const response = data?.response?.data

  console.log("response ", response?.status);
  console.log("Data ", data);
  dispatch(setStoreSocketResponse(payloadRequest));
  setTimeout(() => {
    dispatch(setStoreSocketResponse());
  }, 500);

  switch (data?.url) {
    case 'get_address_from_postal_code':{
      if (data?.response?.status === 200){
        dispatch(setPostCodeList(response));
      }else{
        toast.error( data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'program_list': {
      if (data?.response?.status === 200) {
        dispatch(setProgramList(response))
      } else {
        toast.error( data?.response?.msg || "An error occurred.");
      }
      break;
    }
   
    case 'company_edit': {
      if (data?.response?.status === 200) {
        toast.success( data?.response?.msg)
      } else {
        console.log("data?.response?.msg",data?.response?.msg)
        toast.error( data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'program_add' : {
      if (data?.response?.status === 200) {
        toast.success( data?.response?.msg)
        dispatch(setForm({"url":data?.url,"status":data?.response?.status}))
        dispatch(setProgramData(response))
        dispatch(setConformationPop(true))
      } else {
        toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break
    }
   case 'learner_add':{
    if (data?.response?.status === 200) {
      console.log("learner_add",response)
      dispatch(setLearner(response))
      dispatch(setForm({"url":data?.url,"status":data?.response?.status}))
      dispatch(setConformationPop(true))
    } else {
      toast.error( data?.response?.msg || "An error occurred.");
    }
    break;
   }
    case 'program_detail': {
      if (data?.response?.status === 200) {
        dispatch(setProgramData(response))
      } else {
        toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }

    case 'program_update': {
      if (data?.response?.status === 200) {
        toast.success( data?.response?.msg)
        dispatch(setProgramData(response))
      } else {
        toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }


    case 'learner_edit': {
      if (data?.response?.status === 200) {
        toast.success( data?.response?.msg)
        dispatch(setForm({"url":data?.url,"status":data?.response?.status}))
        dispatch(setUpdateLearner(response))
      } else {
        toast.error(data?.response?.message || "An error occurred.");
      }
      break;
    }
    case'company_list':{
      if(data?.response?.status === 200){
        dispatch(setEmployeeList(response))
      }else{
        toast.error(data?.response?.message || "An error occurred.");
      }
      break;
    }
case 'company_add':{
  if (data?.response?.status === 200) {
    dispatch(setCreateAdminData(response));
    dispatch(setConformationPop(true))
  }else{
    toast.error(data?.response?.message || "An error occurred.");
  }
break;
}

    case 'get_learner_details': {
      if (data?.response?.status === 200) {
        dispatch(setForm({"url":data?.url,"status":data?.response?.status}))
        dispatch(setLearner(response));
      } else {
        toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }

    

case 'get_company':{
  if(data?.response?.status === 200){
    dispatch(setCreateAdminData(response));
  }else{
    toast.error(data?.response?.message || "An error occurred.");
  }
  break;
}
    case 'user_list': {
      if (data?.response?.status === 200) {
        console.log("admin list called status 200 ", data?.request?.user_type)
        switch (data?.request?.user_type) {
          case 'admin': {

            dispatch(setAdminList(response))
            break;
          }
          case 'tutor': {
            dispatch(setTeachingStaffList(response))
            break;
          }
          case 'iqa': {
            dispatch(setIqaList(response))
            break;
          }
          case 'employer': {
            dispatch(setEmployeeList(response))
            break;
          }
          case 'epa': {
            dispatch(setEpaList(response))
            break;
          }
          case 'referrer': {
            dispatch(setRefererList(response))
            break;
          }
          case 'learner': {
            dispatch(setLearnerList(response))
            break;
          }
          case 'manager': {
            console.log("first",response)
            dispatch(setManagerList(response))
            break;
          }
          default:
            break;
        }

      } else {
        console.log("error list called", data?.response?.message)
        toast.error(data?.response?.message || "An error occurred.");
      }
      break;
    }
    case 'contact_list': {
      if (data?.response?.status === 200) {
        dispatch(setStoreContactList(response))
      } else {
        toast.error(data?.response?.message || "An error occurred.");
      }
      break;
    }
    case 'notification_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setNotificationList({ ...data?.response?.data })
        );
      } else {
        dispatch(setNotificationList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_received': {
        toast.success(data?.response?.msg,{
          onClick: () => {
            window.location.href = App_url?.link?.Notifications;
          },
        });
      break;
    }
    case 'progress_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setProgressList({ ...data?.response?.data })
        );
      } else {
        dispatch(setProgressList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setAttendanceList({ ...data?.response?.data })
        );
      } else {
        dispatch(setAttendanceList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'lecture_summary_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setAttendanceList({ ...data?.response?.data })
        );
      } else {
        dispatch(setAttendanceList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'review_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setReviewList({ ...data?.response?.data })
        );
      } else {
        dispatch(setReviewList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'otjh_record_list': {
      if (data?.response?.status === 200) {
        dispatch(
          setOTJHList({ ...data?.response?.data })
        );
      } else {
        dispatch(setOTJHList({
          data: [],
          pagination: {
            current_page: 0,
            record_limit: 0,
            total_records: 0,
          },
        }));
        toast.error(response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_progress_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'notification_delete': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'contact_reply': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg || "Reply send success.");
        dispatch(setStoreContactDetails(response))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_attendance_save': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'bulk_attendance_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'delete_attendance_by_lecture': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_add': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'attendance_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg);
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'group_add': {
      if (data?.response?.status === 200) {
        console.log("group_add",response)
        dispatch(setGroupCreated(response));
        dispatch(setConformationPop(true))
      } else {
        toast.error(data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'group_list': {
      if (data?.response?.status === 200) {
        console.log("group_list")
        dispatch(setGroupList(response))
      } else {
        console.log("no list found")
        dispatch(setGroupList([]))
      }
      break
    }
    case 'group_update': {
      if (data?.response?.status === 200) {
        toast.success(data?.response?.msg)
      } else {
        toast.error(data?.response?.msg)
      }
      break;
    }
    case 'user_add': {
      if (data?.response?.status === 200) {
        if (data?.response?.data?.user_type) {
          dispatch(setCreateAdminData(response));
          dispatch(setForm({"url":data?.url,"status":data?.response?.status}))
          dispatch(setConformationPop(true))
        }

      } else {
        toast.error("Failed to create Admin: " + data?.response?.msg || "An error occurred.");
      }
      break;
    }
    case 'manager_list': {

      if (data?.response?.status === 200) {
        console.log("manager_list", response?.data)
        dispatch(setManagerList(response?.data));
      } else {

      }
      break;
    }
    case 'address_list': {

      if (data?.response?.status === 200) {
        // console.log("address_list -",response?.data)
        // console.log("address_list ------",response?.data?.data)
        dispatch(setAdddressList(response?.data));
      } else {

      }
      break;
    }
    case 'get_user_details': {
      if (data?.response?.status === 200) {
        if (data?.response?.data?.user_type === "admin") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "employer") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "tutor") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "iqa") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "epa") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "referrer") {
          dispatch(setCreateAdminData(response));
        }
        else if (data?.response?.data?.user_type === "manager") {
          console.log("manager",response)
          dispatch(setCreateAdminData(response));
        }
      } else {
        toast.error(data?.response?.msg);
        console.log("user_add error  ::", response);
      }
      break;
    }
    case 'user_edit': {
      if (data?.response?.status === 200) {
        toast.success("User Updated Successfully")
      } else {
        toast.error(data?.response?.msg)
      }
      break;
    }
    case 'unauthorized': {
        toast.error(data?.response?.msg)
        localStorage.clear()
        window.location.href = App_url?.link?.Login;
        window.location.reload()
      break;
    }

    default:
      console.log("Unhandled socket response type:", type);
      break;
  }
})
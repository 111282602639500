import { SET_STORE_REVIEW_LIST } from "./action";
import { IRequestInitialReview } from "./types";


const initialState: IRequestInitialReview = {
  reviewList: {
    data: [],
    pagination: {
      current_page: 0,
      record_limit: 0,
      total_records: 0,
    },
  },
};

const reviewReducer = (state = initialState, action: any): IRequestInitialReview => {
  switch (action.type) {
    case SET_STORE_REVIEW_LIST:
      return {
        ...state,
        reviewList: {
          ...state.reviewList,
          data: action.payload.data ? [...action.payload.data] : [],
          pagination: {
            current_page: action.payload.pagination?.current_page || 0,
            record_limit: action.payload.pagination?.record_limit || 0,
            total_records: action.payload.pagination?.total_records || 0,
          },
        },
      };
    default:
      return state;
  }
};

export default reviewReducer;

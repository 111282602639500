import { SET_DIPLOMA, SET_FEE_STRUCTURE, SET_LEARNING_PLAN, SET_PROGRAM_DATA, SET_PROGRAM_LIST, SET_SKILL_SCAN, SET_STANDARD_DETAIL , SET_FUNCTIONAL_SKILL, SET_CLEAR_VALUE , SET_THE_CLONE_PROGRAM , UPDATE_SKILL_SCAN, SET_SKILL, SET_KNOWLEDGE, SET_BEHAVIOR} from "./action";
import { ProgramResponse } from "./types";

const initialState: ProgramResponse = {
  request : {data : ''} ,
  programDetail : null,
  data:[] ,
  function : [],
  skillScan : null,
  learningPlan: null,
  feesStructure : [],
  diploma : [],
  cloneProgram : [],
  StandardDetail : null,
  knowledge : null,
  skill : null,
  behavior : null
};


const programReducer = (state = initialState, action: any): ProgramResponse => {
  switch (action.type) {
    case SET_CLEAR_VALUE : 
       return initialState ;
    case SET_PROGRAM_DATA:
      return { ...state, programDetail : action.payload };
    case SET_FEE_STRUCTURE : 
    console.log("SET_FEE_STRUCTURE " , action.payload)
        return {...state ,  feesStructure : action.payload};
    case SET_STANDARD_DETAIL :
        return {...state , StandardDetail : action.payload};
    case SET_LEARNING_PLAN :   
        return {...state , learningPlan:action.payload};
    case SET_PROGRAM_LIST:
      console.log("SET_PROGRAM_LIST" , action.payload)
      return { ...state, data:action.payload }; 
    case SET_SKILL_SCAN : 
        return {...state , skillScan:action.payload}   
    case SET_DIPLOMA : 
        return {...state , diploma:action.payload}   
    case SET_FUNCTIONAL_SKILL : 
        return {...state , diploma:action.payload}
    case SET_THE_CLONE_PROGRAM : 
    return {...state , cloneProgram:action.payload}  
    case SET_SKILL : 
    console.log("SET_SKILL " , action.payload)
    return {...state , skill:action.payload}  
    case SET_KNOWLEDGE : 
    console.log("SET_KNOWLEDGE " , action.payload)
    return {...state , knowledge:action.payload}
    case SET_BEHAVIOR : 
    console.log("SET_BEHAVIOR " , action.payload)
    return {...state , behavior:action.payload}  
    case UPDATE_SKILL_SCAN:
      return {
        ...state,
        skillScan: action.payload,
      };     
    default:
      return state;
  }
};

export default programReducer;

import { SET_STORE_NOTIFICATION_LIST } from "./action";
import { IRequestInitialNotification } from "./types";


const initialState: IRequestInitialNotification = {
  notificationList: {
    data: [],
    pagination: {
      current_page: 0,
      record_limit: 0,
      total_records: 0,
    },
    pending:0,
    viewed:0,
  },
};

const notificationReducer = (state = initialState, action: any): IRequestInitialNotification => {
  switch (action.type) {
    case SET_STORE_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          data: action.payload.data ? [...action.payload.data] : [],
          pagination: {
            current_page: action.payload.pagination?.current_page || 0,
            record_limit: action.payload.pagination?.record_limit || 0,
            total_records: action.payload.pagination?.total_records || 0,
          },
          pending: action.payload?.pending || 0,
          viewed: action.payload?.viewed || 0,

        },
      };
    default:
      return state;
  }
};

export default notificationReducer;

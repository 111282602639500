/* eslint-disable */

export const App_url = {
  link: {
    Dashboard: "/",
    Learner: "/learner",
    AddLearner: "/learner/create-learner",

    Admin_Profile: "/profile",

    adminProfileEdit: "/admin/profile/profile-edit",
    employeeProfileEdit: "/employee/profile-edit",
    teachingStaffProfileEdit: "/teachingstaff/profile-edit",
    iqaProfileUpdate: "/iqa/profile-edit",

    adminSign: "/admin/profile/sign-upload",
    LearnerInformation: "/learner-information",
    LearnerDetails: "/learner-information/learner-details",
    ViewBasicDetails:
      "/learner/learner-information/learner-details/basic-detail",
    Ethnicity: "/learner/learner-information/learner-details/ethnicity",
    LearningSupport:
      "/learner/learner-information/learner-details/learning-support",
    PriorEducation:
      "/learner/learner-information/learner-details/prior-education",
    Employment: "/learner/learner-information/learner-details/employment",
    HouseholdSituation:
      "/learner/learner-information/learner-details/household-situation",
    DataProtection:
      "/learner/learner-information/learner-details/data-protection",
    OtherDetails: "/learner/learner-information/learner-details/other-details",
    LearnerInformationStatus:
      "/learner/learner-information/learner-details/status",

    SkillScan: "/learner/learner-information/skill-scan",
    InitialSkillScan:
      "/learner/learner-information/skill-scan/initial-skill-scan",
    MidSkillScan: "/learner/learner-information/skill-scan/mid-skill-scan",
    FinalSkillScan: "/learner/learner-information/skill-scan/final-skill-scan",
    SkillScanStatus: "/learner/learner-information/skill-scan/status",
    SkillScanStatistics: "/learner/learner-information/skill-scan/statistics",

    InitialAssessment: "/learner/learner-information/initial-assessment",
    AssessmentResult:
      "/learner/learner-information/initial-assessment/assessment-result",

    ProgramAllocation: "/learner/learner-information/program-allocation",
    ProgramAllocationInformation:
      "/learner/learner-information/program-allocation/program-allocation-information",

    LearningPlanLearnerInfo: "/learner/learner-information/learning-plan",

    Compliance: "/learner/learner-information/compliance",
    Apprenticeship: "/learner/learner-information/compliance/apprenticeship",
    Commitment: "/learner/learner-information/compliance/commitment",
    Individual: "/learner/learner-information/compliance/individual",
    WrittenAgreement:
      "/learner/learner-information/compliance/written-agreement",
    ComplianceStatus:
      "/learner/learner-information/compliance/compliance-status",

    DeliveryInformation: "/learner/delivery-information",
    LearningPlan: "/learner/delivery-information/learning-plan",
    OTJHRecord: "/learner/delivery-information/otjh-record",
    Attendance: "/learner/delivery-information/attandance",
    Standard: "/learner/delivery-information/attandance/standard",
    Progress: "/learner/delivery-information/progress/standard",
    // Progress: "/delivery-information/progress",
    Reviews: "/learner/delivery-information/reviews",
    MonthlyProgressReport: "/learner/delivery/progress/monthly-progress-report",

    Summary: "/summary",

    // Delivery
    Delivery_OTJHRecord: "/delivery",
    attendance: "/delivery/attendance/attendance",
    listOfLectures: "/delivery/attendance/list-of-lectures",
    addLectures: "/delivery/attendance/add-lectures",
    addProgress: "/delivery/progress/add-progress",
    deliveryProgress: "/delivery/progress/delivery-progress",
    DeliveryReviews: "/delivery/progress/delivery-reviews",

    Users: "/users",
    Admin: "/users/admin",
    CreateAdmin: "/users/admin/create-admin",
    UpdateAdmin: "/users/admin/update-admin",
    Tutor: "/users/teaching-staff",
    CreateTutor: "/users/create-teaching-staff",
    UpdateTutor: "/users/update-teaching-staff",
    Employer: "/users/employer",
    CreateEmployer: "/users/create-employer",
    UpdateEmployer: "/users/update-employer",
    Manager:"/users/manager",
    CreateEmpManager: "/users/create-manager",
    UpdateEmpManager: "/users/update-manager",
    EPA: "/users/epa",
    CreateEPA: "/users/create-EPA",
    UpdateEPA: "/users/update-EPA",
    IQA: "/users/iqa",
    CreateIQA: "/users/create-IQA",
    UpdateIQA: "/users/update-IQA",
    Referrer: "/users/referrer",
    CreateReferrer: "/users/create-referrer",
    UpdateReferrer: "/users/update-referrer",
    Group: "/users/group",
    CreateGroup: "/users/create-group",
    UpdateGroup: "/users/update-group",
    Program: "/program",
    AddStandardProgram: "/program/standard/add",
    AddDiplomaProgram: "/program/diploma/add",
    AddFSProgram: "/program/functional-skill/add",

    Delivery: "/delivery",

    // Learners flow

    learners: "/learners",
    learnersProfileEdit: "/learners/profile-edit",
    Learners: "/learners/onboarding",
    learnerInformation: "/learners/learners-information",
    skillScan: "/learners/skill-Scan",
    learnerContactUs: "/learners/contact-us",
    // Learners: "/learners",
    // learnerInformation : '/learners-information',
    learnerNotification: "/learners/notification",
    // learnerContactUs : '/learners-contact-us',

    learnersCompliance: "/learners/compliance",
    learnersApprenticeship: "/learners/compliance/apprenticeship-agreement",
    learnersCommitment: "/learners/compliance/commitment-statement",
    learnersIndividual: "/learners/compliance/individual-learner-record",
    learnersWrittenAgreement: "/learners/compliance/written-agreement",
    learnersLearningPlan: "/learners/learning-plan/",
    learnersReviews: "/learners/reviews/",
    learnersOtjhRecord: "/learners/OTJH-record/",
    learnersAttendance: "/learners/attendance/",
    learnersProgress: "/learners/progress/Standard",
    learnersSummary: "/learners/summary/",
    learnersBIL: "/learners/break-in-learning/",
    Notifications: "/notifications",
    ContactUs: "/contact-us",
    ViewContactUs: "/contact-us/contact-us-details",

    documents: "/document",

    Login: "/login",
    SignIn: "/",
    SignUp: "/sign-up",
    ForgetPassword: "/forget-password",
    EnterOTP: "/forget-password/otp",
    ResetPassword: "/reset-password",

    ENDPOINT_LINKS: {
      SIGN_IN: "/user/login",
      FORGET_PASSWORD: "/user/otp_send",
      VERIFY_OTP: "/user/otp_verify",
      RESET_PASSWORD: "/user/reset_password",
      LOG_OUT: "/user/logout",
      FILE_UPLOAD: "file/upload",
    },
  },


  BILLinks: {
    bil: "/learner/delivery-information/bil",
    bilWithdraw: "/learner/delivery-information/bil-withdraw",
  },
  API_LINKS:{
REACT_APP_GET_FILE_URL: `${process.env.REACT_APP_ENDPOINT_URL}/file/view/file/`,
FILE_UPLOAD: `${process.env.REACT_APP_ENDPOINT_URL}/file/upload`,
FILE_VIEW: `${process.env.REACT_APP_ENDPOINT_URL}/media/files/`,
  },
  image: {
    userprofile:`${window.location.origin}/assets/image/userprofile.svg`,
    profile: `${window.location.origin}/assets/image/profile.svg`,
    company_logo: `${window.location.origin}/assets/logo/company.svg`,
    dashboard_img: `${window.location.origin}/assets/image/dashboard-img.svg`,
    Learner: `${window.location.origin}/assets/image/Learners.svg`,
    Users: `${window.location.origin}/assets/image/Users.svg`,
    Admin: `${window.location.origin}/assets/image/Users.svg`,
    Tutor: `${window.location.origin}/assets/image/Users.svg`,
    Employer: `${window.location.origin}/assets/image/Users.svg`,
    EPA: `${window.location.origin}/assets/image/Users.svg`,
    IQA: `${window.location.origin}/assets/image/Users.svg`,
    Group: `${window.location.origin}/assets/image/Users.svg`,
    Referre: `${window.location.origin}/assets/image/Users.svg`,
    Delivery: `${window.location.origin}/assets/logo/Delivery.svg`,
    Program: `${window.location.origin}/assets/logo/Program.svg`,
    Banner: `${window.location.origin}/assets/sidebar/Banner.webp`,
    slider: `${window.location.origin}/assets/sidebar/slider.svg`,
    info: `${window.location.origin}/assets/info.webp`,
    Logo: `${window.location.origin}/assets/sidebar/Logo.webp`,
    Down: `${window.location.origin}/assets/image/Down.svg`,
    UserDropdown: `${window.location.origin}/assets/image/UserDropdown.svg`,
    Notification: `${window.location.origin}/assets/image/Notification.svg`,
    UserProfile: `${window.location.origin}/assets/image/UserProfile.svg`,
    LoginBanner: `${window.location.origin}/assets/image/LoginBanner.webp`,
    filter: `${window.location.origin}/assets/image/LoginBanner.webp`,
    add: `${window.location.origin}/assets/image/add.svg`,
    AngleDown: `${window.location.origin}/assets/image/AngleDown.svg`,
    ArrowDown: `${window.location.origin}/assets/image/ArrowDown.svg`,
    arrowleft: `${window.location.origin}/assets/image/arrowleft.svg`,
    Reset: `${window.location.origin}/assets/image/Reset.svg`,
    calendar: `${window.location.origin}/assets/image/calendarIcon.svg`,
    SortList: `${window.location.origin}/assets/image/SortList.svg`,
    FilterSort: `${window.location.origin}/assets/image/FilterSort.svg`,
    Info: `${window.location.origin}/assets/image/Info.svg`,
    Timer: `${window.location.origin}/assets/image/Timer.svg`,
    FileAlt: `${window.location.origin}/assets/image/FileAlt.svg`,
    FileDownload: `${window.location.origin}/assets/image/FileDownload.svg`,
    eye: `${window.location.origin}/assets/image/eye.svg`,
    ArrowUpRight: `${window.location.origin}/assets/image/ArrowUpRight.svg`,
    ArrowRight: `${window.location.origin}/assets/image/ArrowRight.svg`,
    Edit: `${window.location.origin}/assets/image/Edit.svg`,
    Download: `${window.location.origin}/assets/image/Download.svg`,
    schedule: `${window.location.origin}/assets/image/schedule.svg`,
    Delete: `${window.location.origin}/assets/image/Delete.svg`,
    archive: `${window.location.origin}/assets/image/archive.svg`,
    unarchive: `${window.location.origin}/assets/image/unarchive.svg`,
    addIcon: `${window.location.origin}/assets/image/addIcon.svg`,
    Search: `${window.location.origin}/assets/image/Search.svg`,
    Statistic: `${window.location.origin}/assets/image/Statistic.svg`,
    Phone: `${window.location.origin}/assets/image/Phone.svg`,
    NotifyIcon: `${window.location.origin}/assets/image/BellSimpleRinging.svg`,
    Envelope: `${window.location.origin}/assets/image/envelope.svg`,
    Email: `${window.location.origin}/assets/image/EnvelopeSimple.svg`,
    Plus: `${window.location.origin}/assets/image/Plus.svg`,
    PhoneCall: `${window.location.origin}/assets/image/PhoneCall.svg`,
    confirmPopupIcons1: `${window.location.origin}/assets/image/confirmPopupIcons1.svg`,
    confirmPopupIcons2: `${window.location.origin}/assets/image/confirmPopupIcons2.svg`,
    confirmPopupIcons3: `${window.location.origin}/assets/image/confirmPopupIcons3.svg`,
    confirmPopupIcons4: `${window.location.origin}/assets/image/confirmPopupIcons4.svg`,
    Upload: `${window.location.origin}/assets/image/Upload.svg`,
    Download1: `${window.location.origin}/assets/image/Download1.svg`,
    FileDoc: `${window.location.origin}/assets/image/FileDoc.svg`,
    pdfIcon: `${window.location.origin}/assets/image/pdfIcon.png`,
    PencilSimpleLine: `${window.location.origin}/assets/image/PencilSimpleLine.svg`,
    clone: `${window.location.origin}/assets/image/clone.svg`,

    onboarding: `${window.location.origin}/assets/image/onboarding.svg`,
    skillScan: `${window.location.origin}/assets/image/skillScan.svg`,
    complinceIcon: `${window.location.origin}/assets/image/compliance.svg`,
    learnerPlan: `${window.location.origin}/assets/image/learningPlan.svg`,
    review: `${window.location.origin}/assets/image/review.svg`,
    otjhLearner: `${window.location.origin}/assets/image/otjhLearner.svg`,
    attendance: `${window.location.origin}/assets/image/attendanceLearner.svg`,
    progress: `${window.location.origin}/assets/image/progressLearner.svg`,

    ethnicityICon: `${window.location.origin}/assets/Icons/Ethnicity.svg`,
    apprenticeshipLogo: `${window.location.origin}/assets/image/apprenticeshipLogo.svg`,
    ethnicityIcon: `${window.location.origin}/assets/Icons/Ethnicity.svg`,
    learnerInformationIcon: `${window.location.origin}/assets/Icons/learnerInformation.svg`,
    DataProtectionIcon: `${window.location.origin}/assets/Icons/DataProtection.svg`,
    PriorEducationIcon: `${window.location.origin}/assets/Icons/PriorEducation.svg`,
    LearningsupportneedsIcon: `${window.location.origin}/assets/Icons/Learningsupportneeds.svg`,
    HouseholdsituationIcon: `${window.location.origin}/assets/Icons/Householdsituation.svg`,
    EmploymentSectionIcon: `${window.location.origin}/assets/Icons/EmploymentSection.svg`,
    SkillIcon: `${window.location.origin}/assets/Icons/Skill.svg`,
    BehaviourIcon: `${window.location.origin}/assets/Icons/Behaviour.svg`,
    KnowledgeIcon: `${window.location.origin}/assets/Icons/Knowledge.svg`,
    ApprenticeshipAgreementIcon: `${window.location.origin}/assets/Icons/ApprenticeshipAgreement.svg`,
    CommitmentStatementIcon: `${window.location.origin}/assets/Icons/CommitmentStatement.svg`,
    IndividualLearnerRecordIcon: `${window.location.origin}/assets/Icons/IndividualLearnerRecord.svg`,
    WrittenAgreementIcon: `${window.location.origin}/assets/Icons/WrittenAgreement.svg`,
    PlusCircle: `${window.location.origin}/assets/image/PlusCircle.svg`,
    skillFundingLogo: `${window.location.origin}/assets/logo/skill-funding.svg`,
    europeanUnionLogo: `${window.location.origin}/assets/logo/EuropeanUnion.svg`,
    Bgcontactus: `${window.location.origin}/assets/image/Bgcontactus.webp`,
    percentSymbol: `${window.location.origin}/assets/image/percentSymbol.svg`,
    FileIcon: `${window.location.origin}/assets/image/File.png`,
    FileTextIcon: `${window.location.origin}/assets/image/FileText.png`,
    profileBG: `${window.location.origin}/assets/image/profileBG.png`,
    UserCircle: `${window.location.origin}/assets/image/UserCircle.svg`,
  },
  sidebarListWrapper: {
    learner: "learner",
    user: "user",
    delivery: "delivery",
    program: "program",
    notification: "notification",
    contacts: "contacts",
    onboarding: "onboarding",
    skill_scan: "skill_scan",
    compliance: "compliance",
    learning_plan: "learning_plan",
    review: "review",
    otjh: "otjh",
    attendance: "attendance",
    progress: "progress",
    summary: "summary",
    break_in_learning: "break_in_learning",
  },
  user_types: {
    admin: "admin",
    learner: "learner",
    manager: "manager",
    iqa: "iqa",
    tutor: "tutor",
  },
};
export default App_url;

export const SidebarList = [
  {
    title: "Learner",
    route: App_url.link.Learner,
    icon: App_url.image.Learner,
    classIcon:
      "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]",
    permission: [
      App_url?.user_types?.admin,
      App_url?.user_types?.manager,
      App_url?.user_types?.iqa,
      App_url?.user_types?.tutor,
    ],
  },
  {
    title: "Users",
    route: App_url.link.Users,
    icon: App_url.image.Admin,
    classIcon:
      "bg-[linear-gradient(134.67deg,_var(--pink-400)_1.75%,_var(--pink-400)_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--pink-400)_62.77%)]",
    permission: [App_url?.user_types?.admin],
  },
  {
    title: "Delivery",
    route: App_url.link.Delivery,
    icon: App_url.image.Delivery,
    classIcon: "bg-[linear-gradient(152.06deg,_#66C6E4_17.33%,_#408595_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#408595_62.77%)]",
    permission: [
      App_url?.user_types?.admin,
      App_url?.user_types?.iqa,
      App_url?.user_types?.tutor,
    ],
  },
  {
    title: "Program",
    route: App_url.link.Program,
    icon: App_url.image.Program,
    classIcon: "bg-[linear-gradient(135.2deg,_#7EAF7A_3.19%,_#679258_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#679258_62.77%)]",
    permission: [App_url?.user_types?.admin],
  },
  {
    title: "Notifications",
    route: App_url.link.Notifications,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#734D99_62.77%)]",
    permission: [
      App_url?.user_types?.admin,
      App_url?.user_types?.manager,
      App_url?.user_types?.tutor,
    ],
  },
  {
    title: "Documents",
    route: App_url.link.documents,
    icon: App_url.image.FileTextIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#F04949_17.33%,_#DD4F4F_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#DD4F4F_62.77%)]",
    permission: [
      App_url?.user_types?.admin,
      App_url?.user_types?.manager,
      App_url?.user_types?.tutor,
    ],
  },
  {
    title: "Contact Us",
    route: App_url.link.ContactUs,
    icon: App_url.image.Phone,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className:
      "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#FC883F_62.77%)]",
    permission: [
      App_url?.user_types?.admin,
      App_url?.user_types?.manager,
      App_url?.user_types?.iqa,
      App_url?.user_types?.tutor,
    ],
  },

  // {
  //   title: "Profile",
  //   route: App_url.link.adminProfileEdit,
  //   icon: App_url.image.UserCircle,
  //   classIcon:
  //     "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
  //   className: "text-black",
  //   permission: [App_url?.user_types?.admin,App_url?.user_types?.manager, App_url?.user_types?.iqa, App_url?.user_types?.tutor,],
  // },

  {
    title: "Onboarding",
    route: App_url.link.Learners,
    icon: App_url.image.onboarding,
    classIcon:
      "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Skill Scan",
    route: App_url.link.skillScan,
    icon: App_url.image.skillScan,
    classIcon: "bg-[linear-gradient(152.06deg,_#FB7CFF_17.33%,_#E137FF_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Compliance",
    route: App_url.link.learnersCompliance,
    icon: App_url.image.complinceIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },

  {
    title: "Learning Plan",
    route: App_url.link.learnersLearningPlan,
    icon: App_url.image.learnerPlan,
    classIcon: "bg-[linear-gradient(152.06deg,_#CC56FF_17.33%,_#8E3DFF_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Reviews",
    route: App_url.link.learnersReviews,
    icon: App_url.image.review,
    classIcon: "bg-[linear-gradient(270deg,_#3AA6FF_5.71%,_#308AFF_62.77%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "OTJH Record",
    route: App_url.link.learnersOtjhRecord,
    icon: App_url.image.otjhLearner,
    classIcon: "bg-[linear-gradient(153deg,_#EF3AFF_45.71%,_#A91DFA_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Attendance",
    route: App_url.link.learnersAttendance,
    icon: App_url.image.attendance,
    classIcon: "bg-[linear-gradient(150deg,_#77DD18_40.71%,_#1BC649_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Progress",
    route: App_url.link.learnersProgress,
    icon: App_url.image.progress,
    classIcon: "bg-[linear-gradient(152.06deg,_#FFB956_17.33%,_#FF9524_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Summary",
    route: App_url.link.learnersSummary,
    icon: App_url.image.FileIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "BIL",
    route: App_url.link.learnersBIL,
    icon: App_url.image.FileTextIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Notification",
    route: App_url.link.learnerNotification,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
  {
    title: "Documents",
    route: App_url.link.documents,
    icon: App_url.image.FileTextIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "text-black",
    permission: [
      App_url?.user_types?.learner,
    ],
  },
  {
    title: "Contact Us",
    route: App_url.link.learnerContactUs,
    icon: App_url.image.Phone,
    classIcon: "bg-red-700",
    className: "text-black",
    permission: [App_url?.user_types?.learner],
  },
];
export const Variant = {
  outline: {
    className: "border bg-transparent font-[600]",
    icon: "s",
  },
  primary: {
    className:
      "border border-primary bg-primary text-white lg:text-xsm 2xl:text-md font-[600]",
    icon: "white",
  },
  "primary-1": {
    className:
      "border border-primary-1 bg-primary-1 text-white lg:text-xsm 2xl:text-md font-[600] rounded-sm",
    icon: "white",
  },
};

export function dividePercentageCumulativeRounded(numTasks:number= 10, totalPercentage = 100) {
  if (numTasks <= 0) {
      throw new Error("Number of tasks must be greater than zero.");
  }
  if (totalPercentage < 0) {
      throw new Error("Percentage cannot be negative.");
  }

  const taskPercentages = [];
  let cumulative = 0;
  let basePercentagePerTask = totalPercentage / numTasks;

  for (let i = 0; i < numTasks; i++) {
      let currentPercentage;
      if (i < numTasks - 1) {
          currentPercentage = Math.round(basePercentagePerTask);
      } else {
          currentPercentage = totalPercentage - cumulative;
      }

      cumulative += currentPercentage;
      taskPercentages.push(cumulative);
      totalPercentage -= currentPercentage;

      if (i < numTasks - 1) {
          const numRemainingTasks = numTasks - i - 1;
          if (numRemainingTasks > 0) {
              basePercentagePerTask = totalPercentage / numRemainingTasks;
          }
      }
  }

  if (taskPercentages[taskPercentages.length - 1] !== totalPercentage + cumulative) {
      taskPercentages[taskPercentages.length - 1] = totalPercentage + cumulative;
  }

  return taskPercentages;
}
function calculateDates(startDateStr:any, endDateStr:any) {
  const startDate:any = new Date(startDateStr);
  const endDate:any = new Date(endDateStr);

  // Calculate total days
  const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

  // Calculate the middle date
  const middleDate = new Date(startDate.getTime() + Math.floor(totalDays / 2) * 1000 * 60 * 60 * 24);

  // Calculate the date 7 days before the end date
  const endMinus7 = new Date(endDate.getTime() - 7 * 1000 * 60 * 60 * 24);

  // Calculate twelve-week intervals
  const twelveWeekDates = [];
  let currentDate = new Date(startDate);

  while (currentDate < endDate) {
      twelveWeekDates.push(currentDate.toISOString().split("T")[0]);
      currentDate = new Date(currentDate.getTime() + 12 * 7 * 1000 * 60 * 60 * 24);
  }

  // Calculate the last review date
  const lastReviewDate:any = new Date(twelveWeekDates[twelveWeekDates.length - 1]);

  // Calculate gap between the end date and the last review date
  const gapBetweenDates = Math.floor((endDate - lastReviewDate) / (1000 * 60 * 60 * 24));

  // Calculate gateway date
  let gatewayDate = null;
  if (gapBetweenDates >= 25) {
      gatewayDate = new Date(endDate.getTime() - 10 * 1000 * 60 * 60 * 24);
      if (gatewayDate <= new Date(lastReviewDate.getTime() + 15 * 1000 * 60 * 60 * 24)) {
          gatewayDate = new Date(lastReviewDate.getTime() + 15 * 1000 * 60 * 60 * 24);
      }
  }

  // Prepare the result
  const result = {
      total_days: totalDays,
      middle_date: middleDate.toISOString().split("T")[0],
      final: endMinus7.toISOString().split("T")[0],
      twelve_week_dates: twelveWeekDates,
      gateway_date: gatewayDate ? gatewayDate.toISOString().split("T")[0] : ""
  };

  return result;
}

export function formatMonthYear(dateStr:any) {
  if(!dateStr) return
  // Split the input string into year and month
  const [year, month] = dateStr.split('-');

  // Create an array of month names
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Get the month name from the array (note: months are zero-indexed)
  const monthName = months[parseInt(month, 10) - 1];

  // Return the formatted string
  return `${monthName} - ${year}`;
}
// Function to return the appropriate suffix for the month count (1st, 2nd, 3rd, etc.)
export const getMonthSuffix = (month: number) => {
  if (month > 3 && month < 21) {
    return "th"; // Special case for numbers like 11th, 12th, 13th
  }
  switch (month % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function downloadCSV(csvString:any, fileName:string) {
  // Create a Blob object from the CSV string
  var blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

  // Create a temporary link element
  var link = document.createElement("a");
  if (link.download !== undefined) { // Check if the download attribute is supported
      // Set the link's href to a URL created from the Blob
      link.setAttribute("href", URL.createObjectURL(blob));
      // Set the file name
      link.setAttribute("download", fileName);
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the body
      document.body.removeChild(link);
  } else {
      // Fallback for browsers that don't support the download attribute
      console.log("Your browser does not support the download attribute. Please use a modern browser.");
  }
}
export const handleDownloadFile = async (url: string, token: string) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "file.pdf";
    link.click();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export function formatUnderscoreToTitleCase(input: any): string {
  if(!input) return ""
  return input
      .split('_') // Split the string by underscores
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with a space
}


import { LEARNER_ERROR, LEARNER_LOADING, SET_LEARNER , SET_LEARNER_LIST , LEARNER_DETAILS, SET_UPDATE_LEARNER, RESET_FORM} from "./action";
import { LearnerState } from "./types";


const initialState: LearnerState = {
  learnerData: null,
  loading: false,
  learner : [],
  error: null,
  learnerDetails : [],
  learnerForm:null,
  
};

const learnerReducer = (state = initialState, action: any): LearnerState => {
  switch (action.type) {
    case RESET_FORM:
      console.log("RESET_FORM",action.payload)
      return{
        ...state,
        learnerForm: action.payload,
        loading: false,
        error: null,
      };

    case SET_LEARNER:
      return {
        ...state,
        learner: action.payload,
        loading: false,
        error: null,
      };
     
      case SET_LEARNER_LIST:
      return {
        ...state,
        learnerData: action.payload,
        loading: false,
        error: null,
      };


    case LEARNER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LEARNER_ERROR:
      return {
        ...state, 
        loading: false,
        error: action.payload,
      };

    case LEARNER_DETAILS : 
    console.log("learnerDeatils : action.payload :: " , action.payload )
     return {
      ...state ,
      learnerDetails : action.payload,
      loading : false,
      error : action.payload
     } 
     
     case SET_UPDATE_LEARNER : 
     console.log("SET_UPDATE_LEARNER :: " , action.payload )
     return {...state , ...action.payload}

    default:
      return state;
  }
};

export default learnerReducer;

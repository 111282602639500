import { useSelector } from "react-redux";
import { RootState } from "./store";

export const usePosterReducers = () => {
    return useSelector((state: RootState) => {
        return ({
            ...state?.combinedReducer?.request,
            ...state?.combinedReducer?.learner,
            ...state?.combinedReducer?.notification,
            ...state?.combinedReducer?.review,
            ...state?.combinedReducer?.OTJH,
            ...state?.combinedReducer?.attendance,
            ...state?.combinedReducer?.progress,
            user_data:state?.combinedReducer?.user_data,
            programDetail:state?.combinedReducer?.program?.programDetail,
            programClone : state?.combinedReducer?.program?.cloneProgram,
            program:state?.combinedReducer?.program?.data,
            skillScan : state?.combinedReducer?.program.skillScan ,
            standard : state?.combinedReducer?.program.StandardDetail,
            learningPlan : state?.combinedReducer?.program.learningPlan,
            fees : state?.combinedReducer?.program?.feesStructure,
            // learner : state?.combinedReducer?.learner.learnerData,
            learnerDetails : state?.combinedReducer?.learner?.learner,
            user:state?.combinedReducer?.users, 
            contact:state?.combinedReducer?.contact?.contact,
            contactList:state?.combinedReducer?.contact?.contactList,
            diploma : state?.combinedReducer?.program?.diploma,
            reviewList : state?.combinedReducer?.review?.reviewList,
            attendanceList : state?.combinedReducer?.attendance?.attendanceList,
            progressList : state?.combinedReducer?.progress?.progressList,
            OTJHList : state?.combinedReducer?.OTJH?.OTJHList,
            restForm:state?.combinedReducer?.learner?.learnerForm,
        })
    });
  };
import { SET_STORE_CONTACTS_LIST, SET_STORE_CONTACT_DETAILS } from "./action";
import { ContactState } from "./types";

const initialState: ContactState = {
  contactList: null,
  loading: false,
  contact: [],
  error: null,
};

const ContactReducer = (state = initialState, action: any): ContactState => {
  switch (action.type) {
    case SET_STORE_CONTACT_DETAILS:
      return {
        ...state,
        contact: action.payload,
        loading: false,
        error: null,
      };

    case SET_STORE_CONTACTS_LIST:
      return {
        ...state,
        contactList: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default ContactReducer;

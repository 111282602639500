import { ActionTypes } from "./action";

interface UserState {
  company:object| null,
  userCreated: object | null;
  admin_list: [];
  teaching_staff_list: [],
  manager_list: [],
  address_list: [],
  group_list: [],
  employee_list:[],
  iqa_list:[],
  epa_list:[],
  referrer_list:[],
  learner_list:[],
  group: object | null,
  ConfirmationPopup: boolean;
  Search: string;
  postcode:[],
}

const initialState: UserState = {
  company:null,
  userCreated: null,
  admin_list: [],
  teaching_staff_list: [],
  employee_list:[],
  iqa_list:[],
  epa_list:[],
  referrer_list:[],
  manager_list: [],
  address_list: [],
  group_list: [],
  learner_list:[],
  group: null,
  ConfirmationPopup: false,
  Search: '',
  postcode:[],

};

export const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case ActionTypes.SEND_USER_DATA: {
      return { ...state, userCreated: action.payload };
    }
    case ActionTypes.GET_ADMIN_LIST: {
      return { ...state, admin_list: action.payload };
    }
    case ActionTypes.GET_EMPLOYEE_LIST: {
      return { ...state, employee_list: action.payload };
    }
    case ActionTypes.GET_REFERER_LIST: {
      return { ...state, referrer_list: action.payload };
    }
    case ActionTypes.GET_EPA_LIST: {
      return { ...state, epa_list: action.payload };
    }
    case ActionTypes.GET_IQA_LIST: {
      return { ...state, iqa_list: action.payload };
    }
    case ActionTypes.GET_TEACHINGSTAFF_LIST: {
      return { ...state, teaching_staff_list: action.payload };
    }
    case ActionTypes.GET_LEARNER_LIST: {
      return { ...state, learner_list: action.payload };
    }
    case ActionTypes.SHOW_CONFORMTION_POP: {
      return { ...state, ConfirmationPopup: action.payload };
    }
    case ActionTypes.GET_MANAGER_LIST: {
      return { ...state, manager_list: action.payload };
    }
    case ActionTypes.GET_ADDRESS_LIST: {
      return { ...state, address_list: action.payload };
    }
    case ActionTypes.Search: {
      return { ...state, Search: action.payload };
    }
    case ActionTypes.GET_GROUP_LIST: {
      return { ...state, group_list: action.payload }
    }
    case ActionTypes.GROUP_CREATED: {
      return { ...state, group: action.payload }
    }
    case ActionTypes.POST_CODE: {
      return { ...state, postcode: action.payload }
    }
    case ActionTypes.GET_COMPANY: {
      return { ...state, company: action.payload }
    }
    default:
      return state;
  }
};
